
import { defineComponent, onMounted, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import { Actions } from '@/store/enums/StoreEnums'

export default defineComponent({
  name: 'splash-layout',
  components: {},
  setup() {
    const store = useStore()

    onMounted(() => {
      store.dispatch(Actions.ADD_BODY_CLASSNAME, 'bg-body')
    })

    onUnmounted(() => {
      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'bg-body')
    })
  }
})
